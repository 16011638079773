import React from 'react'
import "@passageidentity/passage-elements/passage-auth";

const PassageLogin = () => {
  return (
    <div>
      <div>
        <passage-auth app-id="ixIocFh2NQn8oH0zhIIaVoBU"></passage-auth>
      </div>
    </div>
  );
}

export default PassageLogin